export const Interests = (props) => {
  return (
    <div>
      <h1>
        <span>Interests</span>
      </h1>
      <br />
      <h3>Other Interests</h3>
      <p>
        I enjoy travelling, iced coffee, keeping fit, and the Oxford comma.
      </p>
      <br />
      <figure>
        <figcaption>
          <span id="figtext">Pokemon Centre - Tokyo, Japan - 04/24</span>
          <button></button>
        </figcaption>
        <img
          className="centerImg"
          src="japan.jpg"
          alt="Pokemon Centre - Tokyo, Japan"
        ></img>
      </figure>
      <br />
      <figure>
        <figcaption>
          <span id="figtext">Billabong Sanctuary, Townsville - 12/20</span>
          <button></button>
        </figcaption>
        <img
          className="centerImg"
          src="birdpic.JPG"
          alt="Me with a parrot on my shoulder"
        ></img>
      </figure>
      <br />
    </div>
  );
};
