import React, { useState, useEffect } from "react";

const phrases = ["Hello", "欢迎", "Welcome", "您好", "Hey", "喂"];
//const shuffleChars = '!<>-_\\/[]{}—=+*^?#________';

export const Home = (props) => {
    const [index, setIndex] = useState(0);
    const [dynamicHello, setDynamicHello] = useState(phrases[0]);

    useEffect(() => {
        let myInterval = setInterval(() => {
            let currentIndex = index;
            setDynamicHello(phrases[currentIndex]);
            setIndex((prev) => (prev + 1) % phrases.length);
        }, 1500);
        return () => {
            clearInterval(myInterval);
        };
    });

    return (
        <div className="homepage">
            <h1 className="dynamicHello">{dynamicHello}.</h1>
            <h1>
                <span>I'm Michael.</span>
            </h1>
            <h1>This is my website.</h1>
            <br />
            <p>
                I'm a <strong>Software Engineer</strong> living and working in
                Brisbane.
            </p>
            <br />
            <p>
                I'm interested in web development, design, and problem solving.
                I love all things{" "}
                <span className="fancy-underline">
                    <strong>frontend</strong>
                </span>
                .
            </p>
            <br />
            <p>
                I have frontend experience with <strong>Flutter</strong> and{" "}
                <strong> React</strong>, and API driven backend experience with{" "}
                <strong>Node</strong> + <strong>Express</strong>, and{" "}
                <strong>Golang</strong>.
            </p>
            <br />
            <br />
            <div className="contentImg">
                <p>
                    <strong>Get in contact or view more here:</strong>
                </p>
                <a
                    href="https://github.com/m-doyle"
                    target="_blank"
                    rel="noreferrer"
                >
                    <img
                        alt="github link"
                        src="https://img.icons8.com/material-sharp/192/000000/github.png"
                    />
                </a>
                <a
                    href="https://www.linkedin.com/in/michaeldoyle-developer"
                    target="_blank"
                    rel="noreferrer"
                >
                    <img
                        alt="linkedin link"
                        src="https://img.icons8.com/ios-filled/192/000000/linkedin.png"
                    />
                </a>
            </div>
        </div>
    );
};
