import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router} from 'react-router-dom';
import {Body} from './components/body';
import {Header} from './components/header';
import {Footer} from './components/footer';

ReactDOM.render(
    <>
        <Router>
            <Header />
            <Body />
            <Footer />
        </Router>
    </>,
    document.getElementById('app')
);