import React from "react";

export const Footer = (props) => {
    return (
        <footer>
            <div>
                <a
                    href="https://github.com/m-doyle"
                    target="_blank"
                    rel="noreferrer"
                >
                    <img
                        alt="github link"
                        src="https://img.icons8.com/material-sharp/192/000000/github.png"
                    />
                    GitHub
                </a>
                <a
                    href="https://www.linkedin.com/in/michaeldoyle-developer"
                    target="_blank"
                    rel="noreferrer"
                >
                    <img
                        alt="linkedin link"
                        src="https://img.icons8.com/ios-filled/192/000000/linkedin.png"
                    />
                    LinkedIn
                </a>
            </div>
        </footer>
    );
};
