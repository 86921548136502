import React, { useState } from "react";
import "../styles.css";
import { Home } from "./home";
import { AboutMe } from "./aboutme";
import { Projects } from "./projects";
import { Interests } from "./interests";
import { Routes, Route, Navigate } from "react-router-dom";

// Recieves page from sidebar onClick
export const Contents = (props) => {
  return (
    <div className="ContentContainer">
      <div className="Content">
        <Routes>
          <Route path="/home" element={<Home />} />
          <Route path="/" element={<Navigate replace to="/home" />} />
          <Route path="/experience" element={<AboutMe />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/interests" element={<Interests />} />
        </Routes>
        <br />
      </div>
    </div>
  );
};
