import React, {useState} from 'react';
import {Sidebar} from './sidebar';
import {Contents} from './contents';

// <Sidebar /> will render the navigation box only.
// <Contents /> will render everything on the RHS of the page.
export const Body = (props) => {

    return (
        <div>
            <Sidebar />
            <Contents />
        </div>
    )
}
