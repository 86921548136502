import React from "react";

export const AboutMe = (props) => {
  return (
    <div>
      <h1>
        <span>Experience</span>
      </h1>
      <br />
      <h3>Personal Bio</h3>
      {/* <p>
        I'm a Software Developer with hands-on experience in fullstack web development. I
        have strong mathematics and problem-solving skills, and love tackling
        problems.
      </p> */}
      <p>
        I'm a <strong>Software Engineer</strong> with hands-on experience in fullstack web development. I love
        building software and particularly enjoy working in the frontend.
      </p>
      <br />
      <hr />
      <br />

      <h2>
        <span>Work Experience</span>
      </h2>
      <br />

      <h3>
        Compare The Market Australia
        <span className="workTitle">July 2022 - Present</span>
      </h3>
      <h4>Full Stack Software Engineer</h4>
      <br />
      <p>
      <strong>Taken a lead frontend role in many key projects</strong>, including delivery of the new Pet insurance vertical, major
        initiatives for General insurance customer journeys, and building components for a new design system. 
      </p>
      <br />
      <ul className="workLists">
        <li>
        <strong>Frontend lead role of a $2m initiative</strong> for Car and H&C insurance, which required major refactoring and
          extension of an established React application. 
        </li>
        <li>
        <strong>Led frontend development</strong> for the Pet insurance journey in Flutter, which handles 30k visits per month. 
        </li>
        <li>
          Collaborated with product and UX teams to build reusable UI components for a new design system. 
        </li>
        <li>
        <strong>Maintained and extended multiple React web applications</strong>, overseeing deployments and releases in
          collaboration with QA and product teams.
        </li>
      </ul>
      <br />
      <h4>Tier 1 On-Call Support Team</h4>
      <br />
      <ul className="workLists">
        <li>
          Part of the on-call roster for after-hours production alerts and issues. 
        </li>
        <li>
        <strong>Proficient in AWS</strong>, with hands-on experience troubleshooting cloud issues and alarms. 
        </li>
      </ul>
      <br />
      <figure>
        <figcaption>
          <span id="figtext">Average day in the office (not really)</span>
          <button></button>
        </figcaption>
        <img
          className="centerImg"
          src="meerkat.jpg"
          alt="Me with a meerkat"
        ></img>
      </figure>
      <br />
      {/* <br />
      <figure>
        <figcaption>
          <span id="figtext">Recording B-Roll Footage with Peter Dutton</span>
          <button></button>
        </figcaption>
        <video className="centerImg" controls>
          <source src="peter.mp4" type="video/mp4" />
        </video>
      </figure> */}

      <br />
      <hr />
      <br />
      <h3>Queensland University of Technology</h3>
      <p>
        I graduated with a{" "}
        <strong>
          Bachelor of Information Technology (Computer Science Major)
        </strong>{" "}
        from QUT in July of 2022.
      </p>
      <p>
        My complementary studies consisted of an Economics minor with elective
        law units. My favourite unit was Web Computing, where we built a simple
        frontend in React.
      </p>
      <br />
      <hr />
      <br />
      <h3>Misc. Employment</h3>
      <h4>
        [Dental Assistant] Camp Hill Dental
        <span className="workTitle">Dec 2020 - Jan 2022</span>
      </h4>
      <h4>
        [Office of Peter Dutton MP] Electoral Staffer / Volunteer
        <span className="workTitle">Nov 2017 - Jul 2019</span>
      </h4>
    </div>
  );
};
