import React from "react";

export const Projects = (props) => {
    return (
        <div>
            <h1>
                <span>Projects</span> - view on GitHub{" "}
                <a
                    href="https://github.com/m-doyle"
                    target="_blank"
                    rel="noreferrer"
                >
                    <img
                        width="20px"
                        alt="github link"
                        src="https://img.icons8.com/material-sharp/48/000000/github.png"
                    />
                </a>
            </h1>
            <br />
            <h3>
                QUT Winter Hackathon July 2023 - Grocery Discount Aggregator
            </h3>
            <ul className="workLists">
                <li>
                    Designed, built, and deployed a functional microservice
                    architecture within three days.
                </li>
                <li>
                    Led a team of three students, teaching them microservice
                    architecture and RESTful API basics.
                </li>
                <li>
                    Quickly learned Go, building an aggregator and web scraper
                    from scratch.
                </li>
            </ul>
            <br />
            <figure>
                <figcaption>
                    <span id="figtext">
                        Architecture diagram for aggregator solution
                    </span>
                    <button></button>
                </figcaption>
                <img
                    className="centerImg"
                    src="architecture.jpg"
                    alt="hackathon architecture diagram"
                ></img>
            </figure>
            <br />
            <figure>
                <figcaption>
                    <span id="figtext">Hackathon frontend - Next.js</span>
                    <button></button>
                </figcaption>
                <img
                    className="centerImg"
                    src="hackathon-frontend.png"
                    alt="hackathon frontend"
                ></img>
            </figure>
            <br />
            <hr />
            <br />
            <h3>UQCS Hackathon July 2022 - Flutter To-Do List App</h3>
            <ul className="workLists">
                <li>
                    Designed and built a fully functional Flutter to-do list
                    app, deploying to physical devices within three days.
                </li>
                <li>
                    Led a team of four students, instructing them in Flutter and
                    frontend basics.
                </li>
            </ul>
            <br />
            <figure>
                <figcaption>
                    <span id="figtext">Hackathon app screens</span>
                    <button></button>
                </figcaption>
                <img
                    className="centerImg"
                    src="hackathon-figma.png"
                    alt="honeyroad under construction page"
                ></img>
            </figure>

            <br />
            <hr />
            <br />
            <h3>
                <img
                    className="intextImg"
                    src="bitcoin.png"
                    alt="Bitcoin Logo"
                />{" "}
                Bitcoin E-Commerce Project
            </h3>
            <ul className="workLists">
                <li>
                    <strong>Led Frontend development</strong> of a BTC payment
                    webstore targeting the Australian crypto community.
                </li>
                <li>Mocked an experimental, engaging frontend using Figma.</li>
                <li>
                    Designed and implemented a custom stylesheet based on
                    specific client requirements.
                </li>
            </ul>
            <br />
            <figure>
                <figcaption>
                    <span id="figtext">honeyroad.store - discontinued</span>
                    <button></button>
                </figcaption>
                <img
                    className="centerImg"
                    src="honeyroadfigma.png"
                    alt="honeyroad under construction page"
                ></img>
            </figure>
            <br />
            <hr />
            <br />
            <h3>
                Impact Village (Social Directory Website)
                <span className="workTitle">July 2021 - July 2022</span>
            </h3>
            <h4>Software Developer</h4>
            <br />
            <ul className="workLists">
                <li>
                    Collaborated with a remote team of eight to design and build
                    a social directory based on a client’s vision.
                </li>
                <li>
                    Designed and implemented frontend in React, starting from
                    mock-ups to final production release.
                </li>
                <li>
                    Built a RESTful API backend with Express to connect to an
                    SQL database.
                </li>
                <li>
                    Provided comprehensive instructions to client and
                    documentation for any future developers.
                </li>
            </ul>
            <br />
            <br />
            <figure>
                <figcaption>
                    <span id="figtext">Impact Village Homepage Mockups</span>
                    <button></button>
                </figcaption>
                <img
                    className="centerImg"
                    src="impactvillage.png"
                    alt="Impact Village Homepage Mockups"
                ></img>
            </figure>
            <br />
            <hr />
            <br />
            <h3>Personal Site</h3>
            <p>
                This site! Fully responsive on PC and mobile, I built it from
                scratch using React and vanilla CSS. It is hosted on Vercel with
                a Google domain. No libraries or NPM packages were used on this
                site!
            </p>
            <p>
                Style inspiration for this site came heavily from the Windows 98
                OS.
                <img className="intextImg" src="98.png" alt="Windows 98 Logo" />
            </p>
            <br />
            <figure>
                <figcaption>
                    <span id="figtext">Early site source code</span>
                    <button></button>
                </figcaption>
                <img
                    className="centerImg"
                    src="code.png"
                    alt='Source code for "Personal Site" segment of "Projects"'
                ></img>
            </figure>
        </div>
    );
};
