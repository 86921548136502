import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

// Easily updatable sidebar headings.
const sidebarHeadings = ["Home", "Experience", "Projects", "Interests"];
const sidebarColours = [
  "#A4A4CE",
  "#b9a4ce",
  "linear-gradient(to left, rgb(0,0,123), rgb(8,132,206))",
  "linear-gradient(to right, #e55d87, #5fc3e4)",
  "linear-gradient(to right, #59c173, #a17fe0, #5d26c1)",
  "linear-gradient(79.03deg, rgb(255, 127, 182) 0%, rgb(255, 226, 204) 35.99%, rgb(255, 228, 206) 62.38%, rgb(1, 223, 248) 100%)",
];

export const Sidebar = (props) => {
  const [selected, setSelected] = useState(0);
  const [colours, setColours] = useState(false);
  const navigate = useNavigate();

  // Colour menu contents
  const colourMenu = () => {
    return (
      <li id="cMenu">
        <hr style={{ width: "100%" }} />
        {sidebarColours.map((item, index) => {
          return (
            <>
              <div
                className="square"
                style={{ background: item }}
                onClick={() => {
                  document.body.style.backgroundColor = item;
                  document.body.style.background = item;
                }}
              ></div>
            </>
          );
        })}
        <hr />
      </li>
    );
  };

  // Sets state for the dropdown colours menu
  function handleColourClick() {
    setColours(!colours);
  }

  return (
    <div className="SidebarContainer">
      <div className="Sidebar">
        <ul className="sidebarhoverbox">
          {sidebarHeadings.map((item, index) => {
            return (
              <React.Fragment key={index}>
                <li
                  onClick={() => {
                    // If the same heading is clicked twice
                    if (selected === index) {
                      // Do nothing
                    } else {
                      // Change class of select item
                      setSelected(index);
                    }
                    // can have additional onclick function here
                  }}
                  className={
                    // If <li> is selected - change className
                    selected === index
                      ? "selected-heading"
                      : "unselected-heading"
                  }
                  id={item}
                >
                  <p
                    onClick={() =>
                      navigate("/" + item.toLowerCase().replace(/ /g, ""))
                    }
                  >
                    {item}
                  </p>
                </li>
              </React.Fragment>
            );
          })}
          <li onClick={handleColourClick}>
            <img src="paint.png" alt="Colour selector icon" /> Colours
            <span style={{ float: "right", paddingRight: "10px" }}>
              {colours ? "▴" : "▾"}
            </span>
          </li>
          {colours ? colourMenu() : null}
        </ul>
      </div>
    </div>
  );
};

// onClick={() => navigate('/' + item.toLowerCase().replace(/ /g,''))}
//<Link to={'/' + item.toLowerCase().replace(/ /g,'')}>{item}</Link>
