import React from "react";

export const Header = (props) => {
  return (
    <header>
      <h1>
        <img width="30px" src="headerimg.png" /> michaeldoyle.xyz
      </h1>
    </header>
  );
};
